
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { DeleteJobPostingModel } from "@/domain/interviewer/delete-job-posting/model/DeleteJobPostingModel";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { UpdateJobPostingModel } from "@/domain/interviewer/update-job-posting/model/UpdateJobPostingModel";
import { AddQualificationModel } from "@/domain/interviewer/qualification/add-qualification/model/AddQualificationModel";
import { RemoveQualificationModel } from "@/domain/interviewer/qualification/remove-qualification/model/RemoveQualificationModel";
import striptags from "striptags";

export default defineComponent({
  name: "interview-dropdown",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  props: {
    jobPostingId: Number,
  },
  setup(props) {
    const today = new Date().toISOString().split("T")[0];
    const startDate = ref(today);
    const endDate = ref("");
    const featureInput = ref("");
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const jobPosting = ref<JobPostingListModel>({
      jobPosting: {},
    });
    const isChanged = ref<boolean>(false);

    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
      //       description: Yup.string()
      // .test(
      //   "is-valid-description",
      //   t("validators_error.required"),
      //   (value) => striptags(value || "").trim() !== ""
      // )
      // .required(t("validators_error.required"))
      // .label("description"),
      startDate: Yup.date()
        .required(t("validators_error.required"))
        .label("startDate"),
      endDate: Yup.date()
        .required(t("validators_error.required"))
        .label("endDate")
        .test(
          "is-greater",
          t("validators_error.startDateBiggerEndDate"),
          function (value) {
            const { startDate } = this.parent;
            return startDate
              ? new Date(value as Date) >= new Date(startDate as Date)
              : true;
          }
        ),
      //     features: Yup.array()
      // .of(Yup.string().required(t("validators_error.required")))
      // .min(3, t("validators_error.minFeatures", { min: 3 }))
      // .label("Features"),
    });

    const formatDate = (dateString) => {
      if (!dateString) return ""; // Eğer tarih boşsa, boş string döndür

      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0"); // Gün
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay (0'dan başladığı için 1 ekliyoruz)
      const year = date.getFullYear(); // Yıl

      return `${day}-${month}-${year}`; // gg-aa-yyyy formatında döndür
    };

    const getJobPostingList = (page) => {
      isChanged.value = false;

      isLoading.value = false;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interviews: [],
        },
        page: page,
        pageSize: 50,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            console.log(response.getValue());
            response.getValue().forEach((jobPostingItem) => {
              if (
                jobPostingItem.jobPosting.jobPostingId == props.jobPostingId
              ) {
                jobPosting.value = jobPostingItem;
                if(jobPosting.value.jobPosting.startDate && jobPosting.value.jobPosting.endDate){
                  startDate.value = new Date(jobPosting.value.jobPosting.startDate).toISOString().slice(0, 10);
                  endDate.value = new Date(jobPosting.value.jobPosting.endDate).toISOString().slice(0, 10);
                }
                
              }
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const copyInterview = () => {
      Swal.fire({
        title: t("swalMessages.COPY_JOBPOSTING"),
        text: t("swalMessages.COPY_JOBPOSTING_DESC"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: t("swalMessages.SUCCESSFUL"),
            text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_JOBPOSTING),
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    };

    // Silme işlemi
    const deleteJobPosting = (jobPostingId?: number) => {
      const jobPostingDeleteModel: DeleteJobPostingModel = {
        id: jobPostingId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_JOBPOSTING"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteJobPosting(jobPostingDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_JOBPOSTING),
                  icon: "success",
                }).then(() => {
                  window.location.reload();
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateJobPosting = () => {
      const errors: string[] = [];

      if (
        !jobPosting.value.jobPosting.description ||
        striptags(jobPosting.value.jobPosting.description.trim()) === ""
      ) {
        errors.push(t("validators_error.requiredDesc"));
      }
      if (errors.length > 0) {
        Swal.fire({
          icon: "error",
          title: t("validators_error.title"),
          html: errors.join("<br />"),
        });
        return;
      } else {
        const jobPostingUpdateModel: UpdateJobPostingModel = {
          id: props.jobPostingId,
          title: jobPosting.value.jobPosting.title,
          description: jobPosting.value.jobPosting.description,
          startDate: new Date(startDate.value),
          endDate: new Date(endDate.value),
        };

        interviewerController
          .updateJobPosting(jobPostingUpdateModel)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                title: t("swalMessages.SUCCESSFUL"),
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_JOBPOSTING),
                icon: "success",
              }).then(() => {
                window.location.reload();
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      }
    };

    const addQualification = () => {
      const addQualificatioModel: AddQualificationModel = {
        id: props.jobPostingId,
        text: featureInput.value,
      };

      interviewerController
        .addQualification(addQualificatioModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_ADD_QUALIFICATION),
              icon: "success",
            }).then(() => {
              getJobPostingList(1);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const removeQualification = (qualificationId?: number) => {
      const removeQualificationModel: RemoveQualificationModel = {
        jobPostingId: props.jobPostingId,
        qualificationId: qualificationId,
      };

      console.log(removeQualificationModel);

      interviewerController
        .removeQualification(removeQualificationModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_REMOVE_QUALIFICATION),
              icon: "success",
            }).then(() => {
              getJobPostingList(1);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const editorOptions = ref({
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Başlık seçenekleri
          ["bold", "italic", "underline", "strike"], // Metin stilleri
          [{ list: "ordered" }, { list: "bullet" }], // Liste türleri
          [{ script: "sub" }, { script: "super" }], // Alt ve üst simgeler
          [{ indent: "-1" }, { indent: "+1" }], // Girintiler
          [{ direction: "rtl" }], // Metin yönü
          [{ color: [] }, { background: [] }], // Renkler
          [{ align: [] }], // Hizalama
          ["link"], // Bağlantı
          ["clean"],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
    });

    onMounted(() => {
      getJobPostingList(1);
    });

    return {
      today,
      startDate,
      endDate,
      editorOptions,
      copyInterview,
      deleteJobPosting,
      updateJobPosting,
      jobPosting,
      addQualification,
      removeQualification,
      featureInput,
      form,
    };
  },
});
