
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import { VueEditor } from "vue3-editor";
import JobPostingDropdown from "@/presentation/components/dropdowns/JobPostingDropdown.vue";

export default defineComponent({
  name: "NewJobpostingList",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
    JobPostingDropdown,
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return "";
      }
      return new Intl.DateTimeFormat("tr-TR").format(new Date(date));
    },
  },
  setup() {
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;

    const today = new Date().toISOString().split("T")[0];
    const startDate = ref(today);
    const endDate = ref("");

    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref("");

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const getJobPostingList = async (page) => {
      isChanged.value = false;
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interviews: [],
        },
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            console.log(response.getValue());
            response.getValue().forEach((jobPosting) => {
              totalItems.value = jobPosting.pagination.total;
              currentPage.value = jobPosting.pagination.page;
              pageCount.value = jobPosting.pagination.pageCount;

              // jobPosting.jobPosting.description = getTruncatedDescription(
              //   jobPosting.jobPosting.description
              // );

              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    // const stripHTML = (html) => {
    //   const doc = new DOMParser().parseFromString(html, "text/html");
    //   return doc.body.textContent || "";
    // };

    // // 400 karakterden uzun açıklamayı kesen fonksiyon
    // const getTruncatedDescription = (description) => {
    //   const textContent = stripHTML(description);

    //   if (textContent.length > 400) {
    //     // 400 karakterden uzun ise kes ve üç nokta ekle
    //     return description.substring(0, 750) + "...";
    //   }

    //   // 400 karakterden kısa ise olduğu gibi döndür
    //   return description;
    // };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews) => interviews.slice(0, maxVisible); // Görünen öğeler
    const hiddenItemCount = (interviews) => interviews.length - maxVisible; // Gizli öğe sayısı
    const hiddenItems = (interviews) => interviews.slice(maxVisible); // Gizli öğeler

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const copyInterview = () => {
      Swal.fire({
        title: "Bu Mülakatı Kopyalamak İstiyor Musunuz?",
        text: "Bu mülakatın aynısını kopyalamak ve yeni bir tane oluşturmak istiyor musunuz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: t("swalMessages.SUCCESSFUL"),
            text: "Mülakat başarıyla kopyalandı!",
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    };

    // Filtreleme için seçilen durum
    const status = ref("ALL");

    // Filtrelenmiş ilanları döndüren computed özelliği
    const filteredJobPostings = computed(() => {
      if (status.value === "ALL") {
        return jobPostingList.value;
      }
      return jobPostingList.value.filter(
        (job) => job.jobPosting.status === status.value
      );
    });

    // Duruma göre buton ve badge renklerini yönet
    const filterByStatus = (newStatus: string) => {
      status.value = newStatus;
    };

    const badgeClass = (status: string) => {
      switch (status) {
        case "ACTIVE":
          return "badge badge-light-success";
        case "PENDING":
          return "badge badge-light-warning";
        case "COMPLETED":
          return "badge badge-light-danger";
        default:
          return "badge badge-light-secondary";
      }
    };

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; // Input alanını temizler
      }
    };

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const interviews = ref([
      {
        id: 1,
        title: "Teknik Mülakat",
        description: "Bu mülakat adayın teknik bilgilerini ölçer.",
      },
      {
        id: 2,
        title: "Yabancı Dil Mülakatı",
        description: "Bu mülakat adayın yabancı dil seviyesini ölçer.",
      },
      {
        id: 3,
        title: "Yazılım Test Mülakatı",
        description: "Bu mülakat adayın test becerilerini ölçer.",
      },
      {
        id: 4,
        title: "Star Mülakat",
        description:
          "Bu mülakat adayın deneyimlerini ve kariyerine olan bakış açısını ölçer.",
      },
    ]);

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPostingList(1);
    });

    return {
      url,
      jobPostingList,
      originalJobPostingList,
      form,
      newEndDate,
      changeDateRef,
      isLoading,
      featureInput,
      features,
      addFeature,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      positionDesc,
      copyInterview,
      visibleItems,
      hiddenItemCount,
      status,
      filteredJobPostings,
      filterByStatus,
      badgeClass,
      showAllInterviews,
      showAll,
      hiddenItems,
      today,
      startDate,
      endDate,
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
      visibleRequirements,
      hiddenRequirementCount,
    };
  },
});
